import React from 'react'

import { ILUT_BASE } from '../constants/storage'

const TwoThousandAndThirteen = () => (
  <div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
    <img alt="img" src={`${ILUT_BASE}/2013/collage.jpg`} />
  </div>
)

export default TwoThousandAndThirteen
