import * as React from 'react'

import './Header.scss'

import { useFirebase } from './Firebase'

export const Header = () => {
  const firebase = useFirebase()

  return (
    <header>
      <span>I love you, Taylor.</span>
      <span>
        <button onClick={async () => await firebase.doSignOut()}>Sign Out</button>
      </span>
    </header>
  )
}
