import * as React from 'react'
import app from 'firebase/app'
import 'firebase/auth'

import config from '../../config'

const firebaseConfig = {
  apiKey: config.firebase.API_KEY,
  authDomain: config.firebase.AUTH_DOMAIN,
  databaseURL: config.firebase.DATABASE_URL,
  projectId: config.firebase.PROJECT_ID,
  storageBucket: config.firebase.STORAGE_BUCKET,
  messagingSenderId: config.firebase.MESSAGING_SENDER_ID
}

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig)
    this._auth = app.auth()
  }

  isSignInWithEmailLink() {
    if (this._auth.isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn')

      // The client SDK will parse the code from the link for you.
      this._auth
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn')
          history.replaceState({}, document.title, window.location.href.split('?')[0])
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.log('Error logging in with email redirect:', error)
        })
    }
  }

  sendSignInLinkToEmail(email) {
    var settings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: config.app.EMAIL_REDIRECT_URL,
      // // This must be true.
      handleCodeInApp: true
    }

    this._auth.sendSignInLinkToEmail(email, settings)
  }

  doSignOut() {
    this._auth.signOut()
  }

  onAuthStateChanged(handler) {
    this._auth.onAuthStateChanged(handler)
  }
}

const firebase = new Firebase()
const FirebaseContext = React.createContext()

export const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>
  )
}

export const useFirebase = () => React.useContext(FirebaseContext)
