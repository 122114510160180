import React from 'react'

const Spacer = () => <p style={{ height: '1em' }} />

export const Home = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '50%',
      margin: 'auto',
      minWidth: 300,
      lineHeight: 1.33,
      marginTop: 20,
      border: '1px dashed grey',
      borderRadius: 3,
      padding: 20,
      fontSize: 22
    }}
  >
    <p style={{ alignSelf: 'flex-end' }}>April 2024</p>
    <Spacer />
    <Spacer />
    <p>Tuna,</p>
    <Spacer />
    {/* 2021: The past two years have been quite a ride, my love. From our engagement, to the LC planning, to moving and buying a house in Amsterdam, to fucking COVID and then calling off our wedding, to having a small ceremony in PS instead, and then spending a whole year in lockdown together whilst eating like kings! I am beyond grateful for this life I get to share with you. You're a shining beacon and I know that nothing is beyond reach when I'm together with my tuna. I love you! I love you! I love you! */}
    {/* <p>Like you mentioned yesterday, we've now celebrated more of your birthdays together than otherwise. That is absolutely incredible. I think we are beyond lucky to have connected while we were so young and already - at the ripe young age of 33 - reached such a cool milestone. It makes the honda civic broccoli in me just jump with pride and joy.</p>
    <Spacer />
    <p>Speaking of pride and joy: you're the single best thing in my life, Tuna. I have a hard time saying sappy things to you IRL so let do it now in writing: I often have to literally pinch myself to know that I'm not dreaming anymore when I get up in the morning and leave my room and think about how you'll be downstairs on the couch eagerly waiting to share another day with me in this ONE life. It's an amazing feeling that I will always cherish and cannot overstate the significance of. You add so much color and happiness and laughter and healthy frustration (shall we call it that?) and fancy finedining to my life. Thank you, thank you, thank you ❤️</p>
    <Spacer />
    <p>I am also proud of all the amazing qualities and habits I've adopted from you over the years. I love eating healthy. I love drinking wine and hosting friends. I love doing the right thing, no matter the cost and effort involved. I love having a DOG! I even love the idea of fatherhood -- but only because I love the idea of you as my partner and our child's mother more than anything else! What a lucky kid we will have.</p>
    <Spacer />
    <p>So, without saying more, here's to another wonderful and exciting year together. I will ALWAYS be in your corner. And I promise to spend the rest of my life trying (operative work: trying!) to be the best stupid boy I can be. You deserve it all.</p>
    */}
    {/* 2022: <p>The year that has elapsed since your last birthday has been the single most meaningful year of my life.</p>
    <Spacer />
    <p>You turned 33 and we immediately did something amazing together: conceived a BEAUTIFUL baby boy. The journey that has ensued since then has been nothing short of amazing.</p>
    <Spacer />
    <p>Almost overnight, you transformed into an incredible mother. While Otto was still just a tiny legume, you were already busy figuring out a million logistics to ensure that his life - and our life - would be wonderful.</p>
    <Spacer />
    <p>Otto has everything he needs - both emotionally and materially. And it's because of you. You set us up for success. We have community around us. We have every baby item we needed for this stage. And, so, every day since his birth has just been pure joy.</p>
    <Spacer />
    <p>Being your partner is a very special and honorable feeling. I know how incredibly lucky I am and I am so happy that Otto has you of all the mothers in the world. He's the luckiest kid out there just as I am the luckiest husband (and Mo the luckiest dog!).</p>
    <Spacer />
    <p>I am eternally grateful that you were born on this day 34 years ago in the same town as me such that our paths would cross during our teenage years and we would reach this very moment together.</p>
    <Spacer />
    <p>I will always and forever be yours and love you with all my heart.</p>
    <Spacer />
    <Spacer />
    <span style={{ display: 'flex' }}>- Max (aka Schatzli)</span> */}
    {/* 2023 skipped note... */}
    <p>I continue to have the sensation of loving you more today than yesterday or any other day in the past. Your birthday is always an excellent reminder of that. As I glance back at a decade+ worth of pictures, I can't help but both smile and cry with joy for all that you've allowed me to share with you in this ONE life.</p>
    <Spacer />
    <p>And by "all that you've allowed me to share with you" I really mean "all that you've forced me to see the light on". And goddamn you were always right, because watching you now mother Otto has been the single greatest privilege of my entire life. You're an absolutely fantastic mom. I am so proud and happy that he gets to have you as his mama.</p>
    <Spacer />
    <p>Otto has very clearly inherited the Tuna gene: he's affable, an excellent eater, cute as hell, and the sweetest little soul. I constantly find myself wanting to kiss him in that spot between his checks and nose just as I did with you two decades ago. I would see you scrunch up your face, just as he now does, and my heart would melt and my stupid teenage boy brain would achieve these rare moments of clarity wherein I just KNEW you were the one for me.</p>
    <Spacer />
    <p>Life is very short, but with you and our continued shared history together, it actually feels unending in this amazing way. I know that right now you are in a transitional period between being a mom and pausing full time work. But as with all things you've set your mind on, you are bringing both an incredible tenacity AND grace to this new challenge - and I just KNOW that you'll end up doing exactly what you're meant to do when the time comes.</p>
    <Spacer />
    <p>If Otto becomes 50% of the woman you are today at 35, I know that he'll still make the world a better place and live a happy and fulfilled life. You are the only supernatural force I believe in. And I fucking love you to death.</p>
  </div>
)
