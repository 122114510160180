/* eslint-disable no-undef */

const config = {}

config.firebase = {
  API_KEY: process.env.API_KEY,
  AUTH_DOMAIN: process.env.AUTH_DOMAIN,
  DATABASE_URL: process.env.DATABASE_URL,
  PROJECT_ID: process.env.PROJECT_ID,
  STORAGE_BUCKET: process.env.STORAGE_BUCKET,
  MESSAGING_SENDER_ID: process.env.MESSAGING_SENDER_ID
}

config.app = {
  EMAIL_REDIRECT_URL: process.env.EMAIL_REDIRECT_URL
}

export default Object.freeze(config)
