import * as React from 'react'

import { useFirebase } from '../components/Firebase'

import './SignIn.scss'

export const SignIn = () => {
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const firebase = useFirebase()

  return (
    <div className="SignIn__container">
      <div className="SignIn__sub_container">
        <input
          type="email"
          placeholder="Enter your email..."
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <button
          style={{ marginTop: 18 }}
          disabled={!email}
          onClick={async () => {
            try {
              window.localStorage.setItem('emailForSignIn', email)
              await firebase.sendSignInLinkToEmail(email)
              setEmail('')
              setMessage('Email has been sent. Check your inbox.')
            } catch (err) {
              console.log(err)
              setMessage('An error occurred. Try again.')
            }
          }}
        >
          Submit
        </button>
        <p style={{ marginTop: 18 }}>{message}</p>
      </div>
    </div>
  )
}
