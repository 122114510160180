import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import { history } from './constants/history'
import { FirebaseProvider } from './components/Firebase'
import { AuthenticationProvider } from './components/Authentication'
import { Root } from './Root'

import './style.scss'

const App = () => (
  <FirebaseProvider>
    <AuthenticationProvider>
      <Router history={history}>
        <Root />
      </Router>
    </AuthenticationProvider>
  </FirebaseProvider>
)

ReactDOM.render(<App />, document.getElementById('root'))
