import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useAuthentication } from './components/Authentication'
import { SignIn } from './components/SignIn'
import { Header } from './components/Header'
import { Year } from './components/Year'
import { Home } from './components/Home'
import { Nav } from './components/Nav'
import TwoThousandAndThirteen from './components/TwoThousandAndThirteen'

export const Root = () => {
  const user = useAuthentication()


  if (!user) {
    return <SignIn />
  }

  return (
    <>
      <Header />
      <Nav />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/2013" component={TwoThousandAndThirteen} />
        <Route exact path="/2014">
          {() => <Year hasLg={true} num={36} year={2015} />}
        </Route>
        <Route exact path="/2015">
          {() => <Year hasLg={true} num={36} year={2015} />}
        </Route>
        <Route exact path="/2016">
          {() => <Year hasLg={true} num={30} year={2016} />}
        </Route>
        <Route exact path="/2017">
          {() => <Year hasLg={false} num={36} year={2017} />}
        </Route>
        <Route exact path="/2018">
          {() => <Year hasLg={true} num={62} year={2018} />}
        </Route>
        <Route exact path="/2019">
          {() => <Year hasLg={true} num={53} year={2019} />}
        </Route>
        <Route exact path="/2020">
          {() => <Year hasLg={true} num={61} year={2020} />}
        </Route>
        <Route exact path="/2021">
          {() => <Year hasLg={true} num={34} year={2021} />}
        </Route>
        <Route exact path="/2022">
          {() => <Year hasLg={true} num={52} year={2022} />}
        </Route>
        <Route exact path="/2023">
          {() => <Year hasLg={true} num={88} year={2023} />}
        </Route>
        <Route exact path="/2024">
          {() => <Year hasLg={true} num={102} year={2024} />}
        </Route>
        <Redirect to="/" />
      </Switch>
    </>
  )
}
