import * as React from 'react'

import { ILUT_BASE } from '../constants/storage'

import './Year.scss'

export const Year = ({ year, num, hasLg }) => (
  <div className="Year__container">
    {[...new Array(num)].map((_, i) => {
      const mdUrl = `${ILUT_BASE}/${year}/md/${i + 1}.jpg`
      const lgUrl = `${ILUT_BASE}/${year}/lg/${i + 1}.jpg`

      return (
        <div
          key={i}
          onClick={() => window.open(hasLg ? lgUrl : mdUrl, '_blank')}
          className="bgImage"
          style={{
            cursor: hasLg ? 'zoom-in' : 'auto',
            backgroundImage: `url(${mdUrl}), linear-gradient(315deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)`
          }}
        />
      )
    })}
  </div>
)
