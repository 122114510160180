import React from 'react'
import { NavLink } from 'react-router-dom'

import './Nav.scss'

const Link = props => <NavLink activeClassName="active-nav-link" {...props} />

export const Nav = () => (
  <nav>
    <Link to="/2013">2013</Link>
    <Link to="/2015">2015</Link>
    <Link to="/2016">2016</Link>
    <Link to="/2017">2017</Link>
    <Link to="/2018">2018</Link>
    <Link to="/2019">2019</Link>
    <Link to="/2020">2020</Link>
    <Link to="/2021">2021</Link>
    <Link to="/2022">2022</Link>
    <Link to="/2023">2023</Link>
    <Link to="/2024">2024</Link>
  </nav>
)
