import * as React from 'react'

import { useFirebase } from '../components/Firebase'
import * as Keys from '../constants/storage'

export const AuthenticationContext = React.createContext()

export const AuthenticationProvider = ({ children }) => {
  const firebase = useFirebase()

  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem(Keys.USER)))

  const [withGoogle, setWithGoogle] = React.useState(null)

  const handleAuthStateChanged = (rawUser, error) => {
    if (error) {
      console.log('Firebase auth error:', error)
    }
    if (rawUser) {
      const user = JSON.stringify(rawUser)
      setUser(JSON.parse(user))
      localStorage.setItem(Keys.USER, user)
    } else {
      setUser(null)
      localStorage.removeItem(Keys.USER)
    }
  }

  React.useEffect(() => {
    firebase.onAuthStateChanged(handleAuthStateChanged)
    firebase.isSignInWithEmailLink()
  }, [])

  return (
    <AuthenticationContext.Provider value={user}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = () => React.useContext(AuthenticationContext)
